import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";
import Footer from "../../Components/Footer";
import "./search.scss";
import { searchQuestion } from "../../services/searchQuestion";
import noques from "../../Assets/images/EFEFEFW 1.png";
import { getAllSubject } from "../../services/getAllSubject";
import Button from "../../Components/Buttons/Button";
import library from "../../Assets/icons/library.svg";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Smile_Icon from "../../Assets/images/Smile_Icon.svg";
import sealCheck from "../../Assets/images/sealCheck.svg";
import CommonForm from "../HomePage/Form/CommonForm";
import ScrollTop from "../../Components/Buttons/ScrollTop";
import Navbar from "../../Components/Navbar";
import { CategoryContext } from "../../categoryContext";
import Librarybanner from "./Librarybanner";
import Nav from "../../Components/Nav";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import BonusSection from "../../Components/BonusSection";
import WorldWide from "../../Components/WorldWide";
import AskQue from "../../Components/AskQue";
import { Helmet } from "react-helmet";

const baseURL = process.env.REACT_APP_FILE_BASE_URL;

const Library = () => {
  const [subjectId, setSubjectId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [formData, setFormData] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDataFetching, setIsDataFetching] = useState(true);
  const navigate = useNavigate();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [serviceType, setServiceType] = useState("");
  const [showAll, setShowAll] = useState(false);
  const rowsToShow = showAll ? formData.length : 11;

  const fetchData = async () => {
    try {
      const search = searchParams.get("query");
      const data = await searchQuestion(search, currentPage, 5, subjectId);
      setPageData(data.data);
      setTotalPages(data.totalPages);
      setIsDataFetching(false);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSubjectData = async () => {
    try {
      const response = await getAllSubject();
      setFormData(response.data);
    } catch (error) {
      console.log("Error fetching subject data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    setIsDataFetching(true);
  }, [searchParams]);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, subjectId]);

  useEffect(() => {
    fetchSubjectData();
  }, []);

  const handlePaginationClick = (pageNo) => {
    setCurrentPage(pageNo);
  };

  const handleViewAnswer = (text = "") => {
    navigate(`/homework-help/question-and-answer/${text}`);
  };

  const handlePopup = (serviceType, type, totalSteps) => {
    setIsModelOpen(true);
    setServiceType({ serviceType, type, totalSteps });
    localStorage.setItem("serviceType", serviceType);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const { categoryId } = useContext(CategoryContext);
  const [isModelOpen1, setIsModelOpen1] = useState(false);
  const toggle = () => {
    setIsModelOpen1(!isModelOpen1);
    setIsModelOpen(!isModelOpen);
  };

  return (
    <>
      <Helmet>
        <title>
          Library | Get Personalized Academic Success with DoubtQ | Online
          Question Help
        </title>
        <meta name="description" content="Welcome to the Library of DoubtQ" />
        <meta property="og:title" content="Library - DoubtQ" />
        <meta
          property="og:description"
          content="Explore personalized academic help on DoubtQ's Library"
        />
      </Helmet>
      <Nav />
      <Navbar />
      <div className="row mt-4 mb-4 px-4 m-0">
        <div className="col-lg-12">
          <div className="breadcrumb-inner text-start">
            <ul className="page-list">
              <li className="rbt-breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li>
                <div className="icon-right">
                  <MdOutlineKeyboardArrowRight />
                </div>
              </li>
              <li className="rbt-breadcrumb-item active">Library</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="mb-4 top-que-banner">
        <Librarybanner id={categoryId} setIsModelOpen={setIsModelOpen} />
      </div>
      <Container>
        <div className="library-page search-que">
          <h2 className="text-start">
            Recently asked questions by our students:dff
          </h2>
        </div>
        <div className="mt-4 que-top">
          {pageData && pageData.length > 0 ? (
            pageData.map((item, index) => (
              <div key={index} className="search-que">
                <div
                  className={`library-card d-grid card-${
                    index % 2 === 0 ? "even" : "odd"
                  }`}
                >
                  <div className="que">
                    <div className="text-start d-flex">
                      <span>Q:</span>
                      <p className="mx-2 text-start">
                        {item?.transcriptText &&
                          item.transcriptText.slice(0, 200) +
                            (item.transcriptText.length > 200 ? "..." : "")}
                      </p>
                    </div>
                  </div>
                  {item?.answer && (
                    <div className="que mt-4">
                      <div className="text-start d-flex">
                        <span>A:</span>
                        <div
                          className="mx-2 text-gray text-start"
                          dangerouslySetInnerHTML={{
                            __html: `${item?.answer.slice(0, 20)}${
                              item?.answer.length > 20 ? "..." : ""
                            }`,
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-md-6">
                      <Button
                        text="View answer"
                        varient="search"
                        className="view-button mt-4 p-0 mx-4 d-block"
                        onClick={() => handleViewAnswer(item?.transcriptUrl)}
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="verified-solutions">
                        <img src={sealCheck} alt="sealCheck" className="mb-1" />
                        Verified Solutions
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : isDataFetching ? (
            <div className="fs-4"></div>
          ) : (
            <div>
              <div className="no-que mt-4">
                <img src={noques} alt="no" />
              </div>
              <h2 className="no-data-message mt-4">NO SOLUTION FOUND</h2>
              <div
                style={{
                  backgroundColor: "#f0f0f0",
                  width: "90%",
                  padding: "10px",
                  margin: "50px auto 0",
                  textAlign: "center",
                  borderRadius: "10px",
                }}
              >
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-2 mb-2">
                    <img
                      src={Smile_Icon}
                      alt="smile"
                      style={{ width: "80px" }}
                    />
                  </div>
                  <div
                    className="col-md-3  d-flex align-items-center"
                    style={{
                      position: "relative",
                      textAlign: "left",
                      height: "100%",
                    }}
                  >
                    <h1 style={{ margin: 0, fontWeight: "800" }}>
                      <span style={{ color: "black" }}>Don't</span>{" "}
                      <span style={{ color: "blue" }}>worry</span>
                    </h1>
                    <div
                      style={{
                        position: "absolute",
                        top: "-10px",
                        bottom: "-10px",
                        right: "-10px",
                        borderRight: "2px solid #CCCCCC",
                      }}
                    ></div>
                  </div>
                  <div className="col-md-3 mb-3 px-3">
                    <h1
                      style={{
                        fontSize: "15px",
                        color: "#000000",
                        textAlign: "start",
                      }}
                    >
                      Ask an expert.
                    </h1>
                    <div
                      style={{
                        fontSize: "12px",
                        color: "black",
                        fontWeight: "bold",
                        textAlign: "start",
                      }}
                    >
                      Get a step-by-step solution in as little as 15 minutes!*
                    </div>
                  </div>
                  <div className="col-md-3 mb-3 px-3">
                    <button
                      className="do-my-ass"
                      style={{
                        background:
                          "linear-gradient(90deg, #5956e9 5.64%, #e541ed 96.39%)",
                        boxShadow: "0px 10px 20px rgba(74, 74, 112, 0.1)",
                        border: "2px solid #FFFFFF",
                        fontSize: "14px",
                        color: "#FFF",
                        borderRadius: "9px",
                        padding: "1rem",
                      }}
                      onClick={() => handlePopup("HomeworkHelp", "mcqHelp", 3)}
                    >
                      Ask a Question
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="float-end">
            <Pagination className="mt-4">
              <PaginationItem disabled={currentPage === 1}>
                <PaginationLink
                  previous
                  onClick={() => handlePaginationClick(currentPage - 1)}
                />
              </PaginationItem>
              <PaginationItem active={currentPage === 1}>
                <PaginationLink onClick={() => handlePaginationClick(1)}>
                  1
                </PaginationLink>
              </PaginationItem>
              {totalPages > 2 &&
                [2, 3].map((page) => (
                  <PaginationItem key={page} active={currentPage === page}>
                    <PaginationLink onClick={() => handlePaginationClick(page)}>
                      {page}
                    </PaginationLink>
                  </PaginationItem>
                ))}
              {currentPage > 4 && totalPages > 5 && (
                <PaginationItem disabled>
                  <PaginationLink>...</PaginationLink>
                </PaginationItem>
              )}
              {currentPage > 3 && currentPage < totalPages && (
                <PaginationItem active>
                  <PaginationLink
                    onClick={() => handlePaginationClick(currentPage)}
                  >
                    {currentPage}
                  </PaginationLink>
                </PaginationItem>
              )}
              {currentPage < totalPages - 3 && totalPages > 5 && (
                <PaginationItem disabled>
                  <PaginationLink>...</PaginationLink>
                </PaginationItem>
              )}
              {totalPages > 3 && (
                <PaginationItem active={currentPage === totalPages}>
                  <PaginationLink
                    onClick={() => handlePaginationClick(totalPages)}
                  >
                    {totalPages}
                  </PaginationLink>
                </PaginationItem>
              )}
              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink
                  next
                  onClick={() => handlePaginationClick(currentPage + 1)}
                />
              </PaginationItem>
            </Pagination>
          </div>
        </div>
        <div className="all-subjects mt-4">
          <h3 className="text-start fw-bold">
            Access all questions by various subjects:
          </h3>
          <div className="library-subjects">
            <Row className="mt-4">
              <Col xl={3} lg={6} md={6} xs={6} className="mb-3">
                <Card
                  className={`library-top-card ${
                    subjectId === "" ? "subject-active" : ""
                  }`}
                  onClick={(e) => setSubjectId("")}
                >
                  <img
                    src={library}
                    alt=""
                    style={{ width: "70px", height: "70px" }}
                  />
                  <h4 className="m-0 pt-3">All Subjects</h4>
                </Card>
              </Col>
              {formData?.slice(0, rowsToShow).map((subject, index) => (
                <Col xl={3} lg={6} md={6} xs={6} className="mb-3" key={index}>
                  <Card
                    className={`library-top-card ${
                      subject._id === subjectId ? "subject-active" : ""
                    }`}
                    onClick={(e) => {
                      setSubjectId(subject._id);
                      scrollToTop();
                    }}
                  >
                    <img
                      src={`${baseURL}${subject.image}`}
                      alt=""
                      style={{ width: "70px", height: "70px" }}
                    />
                    <h4 className="m-0 pt-3">{subject.questionSubject}</h4>
                  </Card>
                </Col>
              ))}
              <div className="view-all-btn mt-4 mb-4">
                {!showAll && formData?.length > 11 && (
                  <Button
                    varient="primary"
                    text="View All"
                    onClick={() => setShowAll(true)}
                  />
                )}
                {showAll && (
                  <Button
                    varient="primary"
                    text="View Less"
                    onClick={() => setShowAll(false)}
                  />
                )}
              </div>
            </Row>
          </div>
        </div>
      </Container>
      <AskQue />
      <BonusSection subCategoryId={categoryId} />
      <WorldWide />
      {isModelOpen ? (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={serviceType}
        />
      ) : (
        <></>
      )}
      <Footer />
      <ScrollTop />
    </>
  );
};

export default Library;
